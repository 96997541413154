<!-- Start Navbar Area -->
<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>
<app-spinner></app-spinner>
<div class="navbar-area navbar-style-two">
    <div class="vesax-responsive-nav">
        <div class="container">
            <div class="vesax-responsive-menu">
                <div class="logo">
                    <a class="navbar-brand" routerLink="/"><img
                            src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo"
                            height="180px" width="180px"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="vesax-nav">
        <div class="container-fluid">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img
                        src="https://ucciudaipur.com/wp-content/uploads/2021/11/ucci-logo-update-2.png" alt="logo"></a>
                <div class="collapse navbar-collapse mean-menu">
                    <!-- <form class="navbar-search-box">
                        <label><i class="flaticon-search"></i></label>
                        <input type="text" class="input-search"
                            placeholder="What are you looking for?">
                    </form> -->
                    <ul class="navbar-nav">
                        <!-- Header Section for Guest Start -->
                        <ng-container *ngIf="localStorage && localStorage?.role == roleObject?.guest">
                            <li class="nav-item"><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}" class="nav-link">Home</a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">Apply for Membership</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/user-panel/regular-member-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Regular
                                            Member</a></li>
                                    <li class="nav-item"><a routerLink="/user-panel/hwm-registration" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HWM
                                            Member</a></li>
                                    <li class="nav-item"><a routerLink="/user-panel/non-member-registration"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            Non Member Exporter</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">Services</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item">
                                        <a routerLink="/user-panel/facility-booking" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Facility
                                            Booking</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/user-panel/marketplace-products"
                                            class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                    true}">Marketplace</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/user-panel/dashboard" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">User Panel</a>
                            </li>
                            <li class="nav-item"><a routerLink="/event-grid" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Events</a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/about" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us</a> -->
                            <!-- </li> -->
                            <li class="nav-item"><a routerLink="/website" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">Website</a>
                            </li>
                        </ng-container>
                        <!-- Header Section for Guest End -->

                        <!-- Header Section for Admin || President || CEO Start -->
                        <ng-container
                            *ngIf="localStorage && localStorage?.role == roleObject?.admin || localStorage?.role == roleObject?.president || localStorage?.role == roleObject?.ceo || localStorage?.role == roleObject?.staff">
                            <li class="nav-item"><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                true}" class="nav-link">Home</a>
                            </li>
                            <li class="nav-item"><a href="javascript:void(0)"
                                    class="dropdown-toggle nav-link">Services</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/user-panel/certificate-of-origin-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Certificate of Origin</a></li>

                                    <li class="nav-item"><a routerLink="/user-panel/document-attestation-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Document
                                            Attestation</a></li>

                                    <li class="nav-item"><a routerLink="/user-panel/visa-recommendation"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Visa
                                            Recommendation</a>
                                    </li>

                                    <li class="nav-item"><a routerLink="/user-panel/facility-booking" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Facility
                                            Booking</a>
                                    </li>
                                    <li class="nav-item"><a routerLink="/user-panel/raise-issues" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                            true}">Raise Issues</a></li>
                                    <li class="nav-item"><a routerLink="/marketplace-products" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                        true}">Marketplace</a>
                                    </li>
                                </ul>
                            </li>

                            <li class="nav-item"><a routerLink="/user-panel/dashboard" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">User Panel</a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/dashboard" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">User Panel</a>
                            </li> -->
                            <li class="nav-item"><a routerLink="/event-grid" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Events</a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/about" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us</a>
                            </li> -->
                            <li class="nav-item"><a routerLink="/website" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">Website</a>
                            </li>

                            <!-- <li *ngIf="localStorage && role != 'Guest'" class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">User
                                    Panel</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/dashboard" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Dashboard</a></li>
                                </ul>
                            </li> -->
                        </ng-container>
                        <!-- Header Section for Admin || President || CEO End -->

                        <!-- Header Section for Nominee 1 || Nominee 2 Start -->
                        <ng-container
                            *ngIf="localStorage && localStorage?.role == roleObject?.nominee1 || localStorage?.role == roleObject?.nominee2">

                            <li class="nav-item"><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                true}" class="nav-link">Home</a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/UserLayout" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact:
                                true}" class="nav-link">Home 2</a>
                            </li> -->
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">Apply for Membership</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/user-panel/regular-member-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Regular
                                            Member</a></li>
                                    <li class="nav-item"><a routerLink="/user-panel/hwm-registration" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HWM
                                            Member</a></li>
                                    <li class="nav-item"><a routerLink="/user-panel/non-member-registration"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            Non Member Exporter</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">Services</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/user-panel/certificate-of-origin-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Certificate of Origin</a></li>

                                    <li class="nav-item"><a routerLink="/user-panel/document-attestation-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Document
                                            Attestation</a></li>

                                    <li class="nav-item"><a routerLink="/user-panel/visa-recommendation"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Visa
                                            Recommendation</a>
                                    </li>

                                    <li class="nav-item"><a routerLink="/user-panel/facility-booking" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Facility
                                            Booking</a>
                                    </li>
                                    <!-- <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}"
                                        *ngIf="localStorage.role == roleObject.nominee1|| localStorage.role == roleObject.nominee2 ">
                                        <a routerLink="/user-panel/edit-regular-member" class="nav-link">
                                            <span class="menu-title">Edit Regular Member</span>
                                        </a>
                                    </li> -->
                                    <li class="nav-item"><a routerLink="/user-panel/raise-issues" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Raise Issues</a></li>
                                    <li class="nav-item"><a routerLink="/marketplace-products" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                    true}">Marketplace</a>
                                    </li>
                                    <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Apply for
                                            Membership
                                            <i class='bx bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/regular-member-form" class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Regular
                                                    Member</a></li>
                                            <li class="nav-item"><a routerLink="/hwm-registration" class="nav-link"
                                                    routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">HWM
                                                    Member</a></li>
                                            <li class="nav-item"><a routerLink="/non-member-registration"
                                                    class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact: true}">Registered
                                                    Non Member</a>
                                            </li>
                                        </ul>
                                    </li> -->
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/user-panel/dashboard" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">User Panel</a>
                            </li>
                            <li class="nav-item"><a routerLink="/event-grid" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Events</a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/about" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us</a>
                            </li> -->
                            <li class="nav-item"><a routerLink="/website" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">Website</a>
                            </li>
                        </ng-container>
                        <!-- Header Section for Nominee 1 || Nominee 2 End -->

                        <!-- Header Section for HWM Owner || HWM Contact Person Start -->
                        <ng-container
                            *ngIf="localStorage && localStorage?.role == roleObject?.hwmOwner || localStorage?.role == roleObject?.hwmContact">

                            <li class="nav-item"><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                true}" class="nav-link">Home</a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">Apply for Membership</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/user-panel/regular-member-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Regular
                                            Member</a></li>
                                    <li class="nav-item"><a routerLink="/user-panel/hwm-registration" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HWM
                                            Member</a></li>
                                    <li class="nav-item"><a routerLink="/user-panel/non-member-registration"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            Non Member Exporter</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">Services</a>
                                <ul class="dropdown-menu">

                                    <li class="nav-item"><a routerLink="/user-panel/facility-booking" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Facility
                                            Booking</a>
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a routerLink="/user-panel/edit-hwm-member" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Edit HWM Member</a>
                                    </li> -->
                                    <li class="nav-item"><a routerLink="/user-panel/raise-issues" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Raise Issues</a></li>
                                    <li class="nav-item"><a routerLink="/marketplace-products" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                            true}">Marketplace</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/user-panel/dashboard" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">User Panel</a>
                            </li>
                            <li class="nav-item"><a routerLink="/event-grid" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Events</a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/about" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us</a>
                            </li> -->
                            <li class="nav-item"><a routerLink="/website" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">Website</a>
                            </li>
                        </ng-container>
                        <!-- Header Section for HWM Owner || HWM Contact Person End -->

                        <!-- Header Section for Non Member Start -->
                        <ng-container *ngIf="localStorage && localStorage?.role == roleObject?.nonMember">

                            <li class="nav-item"><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                true}" class="nav-link">Home</a>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">Apply for Membership</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/user-panel/regular-member-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Regular
                                            Member</a></li>
                                    <li class="nav-item"><a routerLink="/user-panel/hwm-registration" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HWM
                                            Member</a></li>
                                    <li class="nav-item"><a routerLink="/user-panel/non-member-registration"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">
                                            Non Member Exporter</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item">
                                <a href="javascript:void(0)" class="dropdown-toggle nav-link">Services</a>
                                <ul class="dropdown-menu">
                                    <li class="nav-item"><a routerLink="/user-panel/certificate-of-origin-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Certificate of Origin</a></li>

                                    <li class="nav-item"><a routerLink="/user-panel/document-attestation-form"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Document
                                            Attestation</a></li>

                                    <li class="nav-item"><a routerLink="/user-panel/visa-recommendation"
                                            class="nav-link" routerLinkActive="active"
                                            [routerLinkActiveOptions]="{exact: true}">Visa
                                            Recommendation</a>
                                    </li>

                                    <li class="nav-item"><a routerLink="/user-panel/facility-booking" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Facility
                                            Booking</a>
                                    </li>
                                    <!-- <li class="nav-item"><a routerLink="/user-panel/edit-non-member" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Edit Non Member</a>
                                    </li> -->
                                    <li class="nav-item"><a routerLink="/user-panel/raise-issues" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Raise Issues</a></li>
                                    <li class="nav-item"><a routerLink="/marketplace-products" class="nav-link"
                                            routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                            true}">Marketplace</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="nav-item"><a routerLink="/user-panel/dashboard" class="nav-link"
                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">User Panel</a>
                            </li>
                            <li class="nav-item"><a routerLink="/event-grid" class="nav-link" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}">Events</a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/about" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us</a>
                            </li> -->
                            <li class="nav-item"><a routerLink="/website" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">Website</a>
                            </li>
                        </ng-container>
                        <!-- Header Section for Non Member End -->

                        <!-- Header Section for Not Logged in Users Start -->
                        <ng-container *ngIf="!localStorage">
                            <li class="nav-item"><a routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                true}" class="nav-link">Home</a>
                            </li>
                            <!-- <li class="nav-item"><a routerLink="/about" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">About Us</a>
                            </li> -->
                            <li class="nav-item"><a routerLink="/website" routerLinkActive="active"
                                    [routerLinkActiveOptions]="{exact: true}" class="nav-link">Website</a>
                            </li>
                        </ng-container>
                        <!-- Header Section for Not Logged in Users End -->


                        <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Home Demo - 1</a></li>

                                <li class="nav-item"><a routerLink="/index-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Home Demo - 2</a></li>
                            </ul> -->
                        <!-- </li> -->

                        <!-- <li class="nav-item" *ngIf="localStorage && role !=
                        roleObject?.guest && localStorage && role !=
                        roleObject?.hwmContact && role !=
                        roleObject?.hwmOwner"><a href="javascript:void(0)"
                                class="dropdown-toggle nav-link">Services</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item" *ngIf="role != 'Guest' && 
                                    localStorage"><a routerLink="/certificate-of-origin-form" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Certificate of Origin</a></li>

                                <li class="nav-item" *ngIf="role != 'Guest' &&
                                    localStorage"><a routerLink="/document-attestation-form" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Document Attestation</a></li>

                                <li class="nav-item" *ngIf="role != 'Guest' &&
                                    localStorage"><a routerLink="/visa-recommendation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Visa Recommendation</a>
                                </li>

                                <li class="nav-item" *ngIf="role != 'Guest' &&
                                    localStorage"><a routerLink="/facility-booking" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Facility Booking</a>
                                </li>

                                <li class="nav-item" *ngIf="localStorage.role ==
                                    roleObject.nonMember"><a routerLink="/edit-non-member" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Edit Non Member</a>
                                <li class="nav-item" *ngIf="localStorage.role ==
                                        roleObject.hwmOwner || localStorage.role
                                        == roleObject.hwmContact">
                                    <a routerLink="/edit-hwm-member" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                            true}">Edit HWM Member</a>
                                </li>
                                <li class="nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}"
                                    *ngIf="localStorage.role == roleObject.nominee1|| localStorage.role == roleObject.nominee2 ">
                                    <a routerLink="/edit-regular-member" class="nav-link">
                                        <!-- <span class="icon"><i class='bx bx-home-circle'></i></span> -->
                        <!-- <span class="menu-title">Edit Regular Member</span>
                                    </a>
                                </li>
                                <li class="nav-item" *ngIf="role !=
                                            'Guest' &&
                                            localStorage"><a routerLink="/raise-issues" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Raise Issues</a></li>
                                <ng-container *ngIf=" role != 'Nominee 1' &&
                                            role != 'Nominee 2'">
                                    <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Apply for
                                            Membership
                                            <i class='bx
                                                        bx-chevron-right'></i></a>
                                        <ul class="dropdown-menu">
                                            <li class="nav-item"><a routerLink="/regular-member-form" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                            true}">Regular
                                                    Member</a></li>
                                            <li class="nav-item"><a routerLink="/hwm-registration" class="nav-link"
                                                    routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                            true}">HWM
                                                    Member</a></li>
                                            <li class="nav-item"><a routerLink="/non-member-registration"
                                                    class="nav-link" routerLinkActive="active"
                                                    [routerLinkActiveOptions]="{exact:
                                                            true}">Registered
                                                    Non Member</a>
                                            </li>

                                            <!-- <li class="nav-item"><a
                                            routerLink="/vertical-listings-right-sidebar"
                                            class="nav-link"
                                            routerLinkActive="active"[routerLinkActiveOptions]="{exact:
                                            true}">Right Sidebar</a></li>
                                    <li class="nav-item"><a
                                            routerLink="/vertical-listings-full-width"
                                            class="nav-link"
                                            routerLinkActive="active"[routerLinkActiveOptions]="{exact:
                                            true}">Full Width</a></li> -->
                        <!-- </ul>
                                    </li>
                                </ng-container> -->

                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Grid Layout <i
                                            class='bx bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/grid-listings-left-sidebar" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Left Sidebar</a></li>

                                        <li class="nav-item"><a routerLink="/grid-listings-right-sidebar" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Right Sidebar</a></li>
                                        <li class="nav-item"><a routerLink="/grid-listings-full-width" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Full Width</a></li>
                                    </ul>
                                </li>


                                <li class="nav-item"><a routerLink="/destinations" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Top Place</a></li>

                                <li class="nav-item"><a routerLink="/categories" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Categories</a></li>

                                <li class="nav-item"><a routerLink="/user-profile" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Author Profile</a></li> -->
                        <!-- </ul>
                        </li>

                        <li *ngIf="localStorage && role != 'Guest'" class="nav-item">
                            <a href="javascript:void(0)" class="dropdown-toggle nav-link">User
                                Panel</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/dashboard" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Dashboard</a></li>
                                <!-- <li class="nav-item"><a routerLink="/dashboard" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Website Dashboard</a></li> -->

                        <!-- <li class="nav-item"><a
                                        routerLink="/raise-issues"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">Raise Issues</a></li> -->
                        <!-- <li class="nav-item"><a
                                        routerLink="/dashboard-messages"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">Messages</a></li>

                                <li class="nav-item"><a
                                        routerLink="/dashboard-bookings"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">Bookings</a></li>

                                <li class="nav-item"><a
                                        routerLink="/dashboard-wallet"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">Wallet</a></li>

                                <li class="nav-item"><a
                                        routerLink="/dashboard-my-listings"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">My Listings</a></li>

                                <li class="nav-item"><a
                                        routerLink="/dashboard-reviews"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">Reviews</a></li>

                                <li class="nav-item"><a
                                        routerLink="/dashboard-bookmarks"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">Bookmarks</a></li>

                                <li class="nav-item"><a
                                        routerLink="/dashboard-add-listings"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">Add Listings</a></li>

                                <li class="nav-item"><a
                                        routerLink="/dashboard-my-profile"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">My Profile</a></li>

                                <li class="nav-item"><a
                                        routerLink="/dashboard-invoice"
                                        class="nav-link"
                                        routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact:
                                        true}">Invoice</a></li> -->
                        <!-- </ul>
                        </li> -->

                        <!-- <li class="nav-item"><a href="javascript:void(0)" class="dropdown-toggle nav-link">Shop</a>
                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/products-list" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Products List</a></li>

                                <li class="nav-item"><a routerLink="/cart" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Cart</a></li>

                                <li class="nav-item"><a routerLink="/checkout" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Checkout</a></li>

                                <li class="nav-item"><a routerLink="/single-products" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Products Details</a></li>
                            </ul>
                        </li> -->
                        <!-- <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact:
                                        true}">Events</a> -->

                        <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/blog-grid" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Blog Grid</a></li>

                                <li class="nav-item"><a routerLink="/blog-right-sidebar" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Blog Right Sidebar</a></li>

                                <li class="nav-item"><a routerLink="/blog-details" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Blog Details</a></li>
                            </ul> -->
                        <!-- </li>
                        <li class="nav-item"><a routerLink="/about" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}" class="nav-link">About Us</a>
                        </li>
                        <li class="nav-item"><a routerLink="/website" routerLinkActive="active"
                                [routerLinkActiveOptions]="{exact:
                            true}" class="nav-link">Website</a> -->
                        <!-- <ul class="dropdown-menu">
                            <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Home Demo - 1</a></li>

                            <li class="nav-item"><a routerLink="/index-2" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                    true}">Home Demo - 2</a></li>
                        </ul> -->
                        <!-- </li> -->
                        <!-- <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">About Us</a></li>

                                <li class="nav-item"><a routerLink="/how-it-works" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">How It Work</a></li>

                                <li class="nav-item"><a routerLink="/pricing" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Pricing</a></li>

                                <li class="nav-item"><a routerLink="/gallery" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Gallery</a></li>

                                <li class="nav-item"><a href="javascript:void(0)" class="nav-link">Events <i class='bx
                                            bx-chevron-right'></i></a>
                                    <ul class="dropdown-menu">
                                        <li class="nav-item"><a routerLink="/events" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Events</a></li>

                                        <li class="nav-item"><a routerLink="/single-events" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                                true}">Events Details</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/error" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">404 Error Page</a></li>

                                <li class="nav-item"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Coming Soon</a></li>

                                <li class="nav-item"><a routerLink="/contact" class="nav-link" routerLinkActive="active" [routerLinkActiveOptions]="{exact:
                                        true}">Contact</a></li>
                            </ul> -->
                    </ul>

                    <div class="others-option d-flex
                                align-items-center">
                        <div class="option-item" *ngIf="!localStorage">
                            <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i
                                    class="flaticon-user"></i> Login /
                                Register</span>
                        </div>
                        <!-- Switch Account Dropdown -->
                        <div class="option-item" *ngIf="localStorage && memberRole?.length > 1">
                            <div class="dropdown profile-nav-item" style="padding-left: 13px;">
                                <a href="#" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="menu-profile">
                                        <span class="name">Switch Account</span>
                                    </div>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-body">
                                        <ul class="profile-nav p-0
                                                    pt-3">
                                            <li class="nav-item" *ngFor="let item of memberRole"><a class="nav-link"><i
                                                        class='bx bx-user'></i>
                                                    <span (click)="getRoleWiseData(item)" pTooltip="{{item.name}}"
                                                        tooltipPosition="top">{{item.name | slice:0:15}}</span></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="option-item" *ngIf="localStorage">
                            <div class="dropdown profile-nav-item">
                                <a href="#" class="dropdown-toggle" role="button" data-bs-toggle="dropdown"
                                    aria-haspopup="true" aria-expanded="false">
                                    <div class="menu-profile">
                                        <span class="name">My Account</span>
                                    </div>
                                </a>
                                <div class="dropdown-menu">
                                    <div class="dropdown-header d-flex
                                                flex-column align-items-center">
                                        <div class="info text-center">
                                            <span class="name" style="color:
                                                        #000;" pTooltip="{{userName |
                                                        titlecase}}" tooltipPosition="bottom">{{userName.slice(0,15)
                                                | titlecase}}</span>
                                            <p class="mb-3 email" pTooltip="{{userEmail}}" tooltipPosition="bottom">
                                                {{userEmail.slice(0,25)}}</p>
                                        </div>
                                    </div>
                                    <div class="dropdown-body">
                                        <ul class="profile-nav p-0
                                                    pt-3">
                                            <li class="nav-item"><a routerLink="/dashboard" class="nav-link"><i class='bx
                                                                bx-user'></i>
                                                    <span>Dashboard</span></a></li>
                                            <!-- <li class="nav-item"><a
                                                            routerLink="/dashboard-messages"
                                                            class="nav-link"><i
                                                                class='bx
                                                                bx-envelope'></i>
                                                            <span>Messages</span></a></li> -->
                                            <li class="nav-item"><a class="nav-link"><i class='bx
                                                                bx-edit-alt'></i>
                                                    <span data-bs-toggle="modal" data-bs-target="#ChangePasswordModal"
                                                        (click)="modelForChangepassword('changepassword')">Change
                                                        Password</span></a></li>
                                            <!-- <li class="nav-item"><a
                                                            routerLink="/dashboard-my-profile"
                                                            class="nav-link"><i
                                                                class='bx
                                                                bx-cog'></i>
                                                            <span>Settings</span></a>
                                                    </li> -->
                                        </ul>
                                    </div>
                                    <div class="dropdown-footer">
                                        <ul class="profile-nav">
                                            <li class="nav-item">
                                                <div>
                                                    <a class="nav-link" (click)="logout()"><i class='bx
                                                                    bx-log-out'></i>
                                                        <span>Logout</span></a>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i>
                                Add Listing</a>
                        </div> -->
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <!-- <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div> -->
            <div class="container">
                <div class="option-inner">
                    <div class="others-option">
                        <div class="option-item">
                            <form class="navbar-search-box">
                                <label><i class="flaticon-search"></i></label>
                                <input type="text" class="input-search" placeholder="What are you looking
                                            for?">
                            </form>
                        </div>

                        <div class="option-item">
                            <span data-bs-toggle="modal" data-bs-target="#loginRegisterModal"><i
                                    class="flaticon-user"></i> Login /
                                Register</span>
                        </div>

                        <div class="option-item">
                            <a routerLink="/dashboard-add-listings" class="default-btn"><i class="flaticon-more"></i>
                                Add Listing</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar Area -->

<!-- Start Login & Register Modal -->
<div class="modal fade loginRegisterModal" id="loginRegisterModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <!-- <button type="button" (click)="resetForm()" class="close" data-bs-dismiss="modal" id="close_btn"
                aria-label="Close"><i class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link {{classCheck == 'login' || classCheck == 'changepassword' ? 'active' : ''}}"
                        (click)="classCheck= 'login'" id="login-tab" data-bs-toggle="tab" data-bs-target="#login"
                        type="button" role="tab" aria-controls="login" aria-selected="true">Login</button>
                </li>
                <li class="nav-item">
                    <button class="nav-link {{classCheck == 'register' ? 'active' :
                                ''}}" (click)="classCheck= 'register'" id="register-tab" data-bs-toggle="tab"
                        data-bs-target="#register" type="button" role="tab" aria-controls="register"
                        aria-selected="true">Register</button>
                </li>
            </ul> -->
            <div class="tab-content" id="myTabContent">
                <!-- Login Form -->
                <div class="tab-pane fade {{classCheck == 'login' ? 'show active' : ''}}" id="login" role="tabpanel">
                    <div class="vesax-login">
                        <!-- Login form -->
                        <form [formGroup]="loginForm" (ngSubmit)="loginIn()" #formlogin="ngForm">
                            <div class="form-group">
                                <input type="email" scriptInjection placeholder="Username or Email"
                                    formControlName="loginemail" maxlength="50" class="form-control"
                                    pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                <div *ngIf="submitted &&
                                            g.loginemail.errors?.required ||
                                            g.loginemail.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="g.loginemail.errors?.required">Email
                                        is Required
                                    </div>
                                    <div *ngIf="g.loginemail.errors?.pattern">Please
                                        Enter a Valid Email
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" formControlName="loginpassword" placeholder="Password"
                                    minlength="8" [class.is-invalid]="submitted
                                            &&
                                            registerForm.get('loginpassword')?.invalid" required class="form-control">
                                <div *ngIf="submitted &&
                                            g.loginpassword.errors?.required
                                            || g.loginpassword.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="g.loginpassword.errors?.required">Password is Required
                                    </div>
                                    <div *ngIf="g.loginpassword.errors?.minlength">Password Must be at Least 8
                                        Characters Long
                                    </div>
                                </div>
                            </div>
                            <button type="submit" [disabled]="disablebtn">Login Now</button>
                        </form>
                        <span class="dont-account">Don't have an
                            account?
                            <a (click)="classCheck= 'register'">Register Now</a>
                        </span>
                        <span class="dont-account"> <a (click)="classCheck= 'forgot'">Forgot Password</a></span>
                    </div>
                </div>
                <!-- Register Form -->
                <div class="tab-pane fade {{classCheck == 'register' ? 'show active' :
                            ''}}" id="register" role="tabpanel">
                    <div class="vesax-register">
                        <!-- Register form -->
                        <form [formGroup]="registerForm" (ngSubmit)="signUp()" #formRegister="ngForm">
                            <!-- pop ups -->
                            <div class="form-group">
                                <!-- appHtmlInjection -->
                                <input type="text" scriptInjection formControlName="signupname" placeholder="Name"
                                    class="form-control" (change)="onChange($event.target.value)"
                                    style="text-transform: capitalize;" pattern="[a-zA-Z0-9]|[./!@#$%^&*()-_,?]">
                                <div *ngIf="signUpSubmitted &&
                                            f.signupname.errors?.required
                                            ||
                                            f.signupname.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signupname.errors?.required">Name
                                        is Required
                                    </div>
                                    <div *ngIf="f.signupname.errors?.pattern">Please
                                        Enter a Valid User Name
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="email" scriptInjection formControlName="signupemail" placeholder="Email"
                                    class="form-control" pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                <div *ngIf="signUpSubmitted &&
                                            f.signupemail.errors?.required ||
                                            f.signupemail.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signupemail.errors?.required">Email
                                        is Required
                                    </div>
                                    <div *ngIf="f.signupemail.errors?.pattern">Please
                                        Enter a Valid Email
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" formControlName="signuppassword" placeholder="Password"
                                    minlength="8" [class.is-invalid]="signUpSubmitted
                                            &&
                                            registerForm.get('signuppassword')?.invalid" required class="form-control">
                                <div *ngIf="signUpSubmitted &&
                                            f.signuppassword.errors?.required
                                            || f.signuppassword.touched" class="alert
                                            alert-danger">
                                    <div *ngIf="f.signuppassword.errors?.required">Password
                                        is Required
                                    </div>
                                    <div *ngIf="f.signuppassword.errors?.minlength">Password
                                        Must be at Least 8 Characters
                                        Long
                                    </div>
                                </div>
                            </div>
                            <div class="form-group">
                                <input type="password" formControlName="confirmpassword" placeholder="Confirm Password"
                                    minlength="8" [class.is-invalid]="signUpSubmitted
                                            &&
                                            registerForm.get('confirmpassword')?.invalid" required
                                    class="form-control">
                                <div *ngIf="signUpSubmitted &&
                                            f.confirmpassword.errors?.required
                                            || f.confirmpassword.touched" class="alert
                                            alert-danger">
                                    <div class="invalid-feedback">
                                        Confirm Password is Required
                                    </div>
                                    <div *ngIf="f.confirmpassword.errors?.required">Confirm
                                        Password is Required
                                    </div>
                                </div>
                                <div *ngIf="registerForm.get('confirmpassword').value" class="alert
                                            alert-danger">
                                    {{
                                    registerForm.get('signuppassword').value
                                    === "" &&
                                    registerForm.get('confirmpassword').value
                                    != "" ?
                                    "Enter Password First" : "" }} {{
                                    registerForm.get('signuppassword').value
                                    != "" &&
                                    registerForm.get('signuppassword').value
                                    !=
                                    registerForm.get('confirmpassword').value
                                    ? "Password Not Match" : "" }}
                                </div>
                            </div>
                            <button type="submit">Register Now</button>
                        </form>
                        <span class="already-account">Already have an
                            account?
                            <a (click)="classCheck= 'login'">Login Now</a></span>
                    </div>
                </div>
                <!-- Forgot Password Form -->
                <div class="tab-pane fade {{classCheck == 'forgot' ? 'show active' :
                            ''}}" id="forgot" role="tabpanel">
                    <div class="vesax-register">
                        <!-- Register form -->
                        <form [formGroup]="forgotForm" (ngSubmit)="forgotGetOtp(forgotSubmit)" #forgotSubmit="ngForm">
                            <!-- pop ups -->
                            <ng-container *ngIf="!changepassword">
                                <div class="form-group">
                                    <ng-container *ngIf="!showOtp">
                                        <input type="email" scriptInjection formControlName="emailAddress"
                                            placeholder="Email Address" class="form-control"
                                            pattern="[^@\s]+@[^@\s]+\.[^@\s]+">
                                        <div class="alert alert-danger"
                                            *ngIf="forgotForm.get('emailAddress').errors?.required && forgotSubmit.submitted || forgotForm.get('emailAddress').errors?.required && forgotForm.get('emailAddress')?.touched">
                                            Email Address is Required</div>
                                        <div class="alert alert-danger"
                                            *ngIf="forgotForm.get('emailAddress').errors?.pattern && forgotSubmit.submitted || forgotForm.get('emailAddress').errors?.pattern && forgotForm.get('emailAddress')?.touched">
                                            Enter Valid Email Address</div>
                                    </ng-container>
                                    <ng-container *ngIf="showOtp">
                                        <span
                                            class="form-control d-flex align-items-center">{{forgotForm.value.emailAddress}}</span>
                                    </ng-container>
                                </div>
                                <div class="form-group" *ngIf="showOtp">
                                    <input type="text" scriptInjection formControlName="otp" maxlength="6"
                                        placeholder="Enter OTP" class="form-control"
                                        style="text-transform: capitalize;">
                                    <div *ngIf="forgotSubmit.submitted || forgotForm.get('otp').touched" class="alert
                                                alert-danger">
                                        <div class="alert alert-danger" *ngIf="forgotForm.get('otp').errors?.required">
                                            Enter OTP</div>
                                        <div class="alert alert-danger" *ngIf="forgotForm.get('otp').errors?.pattern">
                                            Enter Valid OTP</div>
                                    </div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="changepassword">
                                <div class="form-group">
                                    <input type="email" formControlName="password" placeholder="Enter Password"
                                        class="form-control">
                                    <div class="alert alert-danger"
                                        *ngIf="forgotForm.get('password').errors?.required && forgotSubmit.submitted || forgotForm.get('password').errors?.required && forgotForm.get('password')?.touched">
                                        Password is Required</div>
                                </div>
                                <div class="form-group">
                                    <input type="text" scriptInjection formControlName="confirmpassword"
                                        placeholder="Enter Confirm Password" class="form-control"
                                        style="text-transform: capitalize;">
                                    <div *ngIf="forgotSubmit && forgotForm.get('confirmpassword').errors?.required
                                                ||
                                                forgotForm.get('confirmpassword').touched && forgotForm.get('confirmpassword').errors?.required"
                                        class="alert
                                                alert-danger">
                                        <div class="alert alert-danger">Confirm Password is Required</div>
                                    </div>
                                </div>
                            </ng-container>
                            <button type="submit">{{forgotButton}}</button>
                        </form>
                        <span class="already-account">Already have an
                            account?
                            <a (click)="classCheck= 'login'">Login Now</a></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Login & Register Modal -->


<!-- *****************************************************Change Password Modal**************************************************************** -->
<div class="modal fade loginRegisterModal" id="ChangePasswordModal" tabindex="-1" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <button type="button" class="close" data-bs-dismiss="modal" id="close_btn" aria-label="Close"><i
                    class='bx bx-x'></i></button>
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                    <button class="nav-link {{classCheck == 'changepassword' ? 'show active' :
                                ''}}" (click)="classCheck = 'changepassword'" id="login-tab" data-bs-toggle="tab"
                        data-bs-target="#login" type="button" role="tab" aria-controls="login"
                        aria-selected="true">Change Password</button>
                </li>
            </ul>
            <!-- Change Password Form -->
            <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade {{classCheck == 'changepassword' ? 'show active' : ''}}" id="changepassword"
                    role="tabpanel">
                    <div class="vesax-login">
                        <form [formGroup]="PasswordForm" (ngSubmit)="changePassword()" #changePassForm="ngForm">
                            <div class="my-profile-box">
                                <div class="" *ngIf="!showData">
                                    <div class="row">
                                        <div class="form-group">
                                            <label>Email</label>
                                            <input value="{{UserEmail}}" readonly class="form-control">
                                        </div>
                                        <div class="form-group">
                                            <label>New Password</label>
                                            <input type="password" formControlName="newpassword"
                                                placeholder="New Password" minlength="8" [class.is-invalid]="(changePassForm.submitted
                                                        &&
                                                        PasswordForm.get('newpassword')?.invalid)
                                                        ||
                                                        PasswordForm.get('newpassword')?.invalid
                                                        &&
                                                        PasswordForm.get('newpassword').touched" class="form-control">
                                            <div *ngIf="changePassForm.submitted
                                                        &&
                                                        p.newpassword.errors?.required
                                                        ||
                                                        p.newpassword.touched" class="alert
                                                        alert-danger">
                                                <div *ngIf="p.newpassword.errors?.required">Password
                                                    is Required
                                                </div>
                                                <div *ngIf="p.newpassword.errors?.minlength">Password
                                                    Must be at Least 8
                                                    Characters
                                                    Long
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label>Confirm Password</label>
                                            <input type="password" formControlName="confirmpassword"
                                                placeholder="Confirm Password" minlength="8" [class.is-invalid]="(changePassForm.submitted
                                                        &&
                                                        PasswordForm.get('confirmpassword')?.invalid)
                                                        ||
                                                        PasswordForm.get('confirmpassword')?.invalid
                                                        &&
                                                        PasswordForm.get('confirmpassword').touched"
                                                class="form-control">
                                            <div *ngIf="changePassForm.submitted
                                                        &&
                                                        p.confirmpassword.errors?.required
                                                        ||
                                                        p.confirmpassword.touched" class="alert
                                                        alert-danger">
                                                <div class="invalid-feedback">
                                                    Confirm Password is
                                                    Required
                                                </div>
                                                <div *ngIf="p.confirmpassword.errors?.required">Confirm
                                                    Password is Required
                                                </div>
                                            </div>
                                            <div *ngIf="PasswordForm.get('confirmpassword').value" class="alert
                                                        alert-danger">
                                                {{
                                                PasswordForm.get('newpassword').value
                                                === "" &&
                                                PasswordForm.get('confirmpassword').value
                                                != "" ?
                                                "Enter Password First" :
                                                "" }} {{
                                                PasswordForm.get('newpassword').value
                                                != "" &&
                                                PasswordForm.get('newpassword').value
                                                !=
                                                PasswordForm.get('confirmpassword').value
                                                ? "Password Not Match" :
                                                "" }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="showData">
                                    <div class="form-group">
                                        <label>OTP<span class="required">*</span>:</label>
                                        <input [class.is-invalid]="(changePassForm.submitted
                                                    &&
                                                    PasswordForm.get('otp')?.invalid)
                                                    ||
                                                    PasswordForm.get('otp')?.invalid
                                                    &&
                                                    PasswordForm.get('otp').touched" type="text" scriptInjection
                                            class="form-control" placeholder="Enter OTP" maxlength="6"
                                            formControlName="otp">

                                        <div class="invalid-feedback">
                                            <div *ngIf="p.otp.errors?.required">
                                                OTP is Required
                                            </div>
                                            <div *ngIf="p.otp.errors?.pattern">
                                                OTP is Required
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12
                                            col-md-12">
                                    <div class="form-group">
                                        <button type="submit">{{submitButton}}</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- *****************************************************Role Selection Modal******************************************************************************** -->
<div class="role-modal">
    <p-dialog p-dialog header="Role" [breakpoints]="{'850px' : '90vw'}" #dialog [modal]="true" [closeOnEscape]="false" [closable]="false"
        [style]="{width: '42vw', height: '398px'}" [draggable]="false" [resizable]="false" [(visible)]="Modal">
        <form action="" [formGroup]="roleForm" (ngSubmit)="sendRole(role)" #role="ngForm" style="height: 100%;">
            <div class="form-group">
                <label class="mb-3">Please Select Role<span class="required">*</span>:</label>
                <p-dropdown [options]="roles" placeholder="Please Select Role" [class.is-invalid]="(role.submitted &&
            roleForm.get('memberrole')?.invalid) ||
            roleForm.get('memberrole')?.invalid &&
            roleForm.get('memberrole').touched" formControlName="memberrole" optionLabel="name" optionValue="value"
                    [showClear]="false" [resetFilterOnHide]="true">
                </p-dropdown>
                <div *ngIf="role.submitted &&
            o.memberrole.errors?.required ||
            o.memberrole.touched" class="alert
            alert-danger">
                    <div *ngIf="o.memberrole.errors?.required">
                        Role is Required
                    </div>
                </div>
            </div>
            <button style="position: relative;
            top: 110px;" pButton label="Proceed" class="p-button-outlined
                my-3" style="float: right;"></button>
        </form>
    </p-dialog>
</div>