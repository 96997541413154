<p-toast
    [breakpoints]="{'575px': {width: '100%', right: '0', left: '0', display: 'flex', 'justify-content': 'center', 'align-items': 'center', 'flex-direction': 'column'}}">
</p-toast>


<!-- <app-spinner></app-spinner> -->
<p-dialog header="UCCI EXCELLENCE AWARDS 2025" [(visible)]="isAwards" [modal]="true"
    [style]="{ width: '550px'}" [draggable]="false" [resizable]="false">
        <div>
            <img style="width: 100%; cursor: pointer;" (click)="navigateToAwards()" src="assets/img/Ucci-awards.jpg" alt="">
        </div>
  </p-dialog>

<section class="allSection">
    <section class="bannerSection" id="banner">
        <img src="assets/img/banner update-01.png" style="width: 100%;">

        <div class="row m-0 bannerRow">
            <!--****************** new code for awards (temp) ******************-->
            <div class="bannerContentDiv">
                <a href="https://ucciudaipur.com/aao-factory-dekhein" class="homeBUtton mr-2 button1">Discover More</a>
                <a href="https://ucciudaipur.com/services/vocational-training-centre"
                    class="homeBUtton mr-2  button2">Discover More</a>
                <a href="https://ucciudaipur.com/services" class="homeBUtton  button3">Discover More</a>
            </div>
            <!--****************** previous code (permanent) ******************-->
            <!-- <div class="col-md-7 col-12">
                <div class="bannerContentDiv">
                    <h1 class="bannerContent">{{data?.section1?.headline}}</h1>
                    <button (click)="navigate(data?.section1?.slug)" class="homeBUtton">Explore</button>
                </div>
            </div>
            <div class="col-md-5 col-12 sliderSection">
                <div class="sliderDiv h-100">
                    <p-carousel [value]="data?.section1?.images" styleClass="custom-carousel" [numVisible]="1" [numScroll]="1"
                        [circular]="true" [autoplayInterval]="3000">
                        <ng-template let-images pTemplate="item">
                            <div class="product-item">
                                <div class="product-item-content" style="text-align: right;">
                                    <img src="{{images?.image}}" class="product-image" />
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>
                </div>
            </div> -->
        </div>
    </section>
    <section class="backgroundSection">
        <span class="circleDesignOne"></span>
        <div class="row m-0 backgroundRow">
            <div class="col-md-7">
                <h2 class="aboutHeading"><span class="backgroundDesign">{{data?.section2?.heading}}</span></h2>
                <h3 class="aboutSubHeading">{{data?.section2?.subheading}}</h3>
                <p class="aboutContent" [innerHTML]="data?.section2?.content"></p>
                <button class="homeBUtton" (click)="navigate(data?.section2?.slug)">Discover More</button>
            </div>
            <div class="col-md-5">
                <div class="aboutImage">
                    <img src="{{data?.section2?.image}}" alt="">
                </div>
            </div>
        </div>
    </section>
    <section class="serviceSection">
        <div class="row m-0">
            <div class="col-md-8 col-12">
                <p class="serviceHeading">{{data?.section3?.heading}}</p>
            </div>
            <div class="col-md-5 col-0"></div>
        </div>
        <div class="cardParentDiv row m-0">
            <div class="serviceCol col-md-4 col-lg-3 col-12" *ngFor="let item of data?.section3?.service">
                <div class="serviceCard" (click)="navigate(item.slug)">
                    <div class="serviceImage">
                        <img src="{{item?.image}}" alt="">
                    </div>
                    <p class="serviceName">{{item?.subheading}}</p>
                    <div class="serviceContent">
                        <p class="m-0 contentPara" [innerHTML]="item?.content"></p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <section class="ucciSection">
        <span class="circleDesignTwo"></span>
        <div class="whyUCCI">
            <svg class="largeSVG" viewBox="-50 -2.5 100 150">
                <!-- <path d="
                M 15,0
                L 30,0
                Q 35, 0
                  35, 3
                L 35, 7
                Q 35, 10
                  30, 10
                L 5, 10
                Q 0, 10
                  0, 13 
                L 0, 58.5
                Q 0, 61.5
                  1.5, 61.5
            " /> -->
                <!-- <path d="
                M 15,0
                L 30,0
                Q 35, 0
                  35, 3
                L 35, 7
                Q 35, 10
                  30, 10
                L 5, 10
                Q 0, 10
                  0, 13 
                L 0, 64
                Q 0, 64
                  1.5, 66
            " /> -->
                <path d="
                M 15,0
                L 32,0
                Q 37, 0
                  37, 3
                L 37, 7
                Q 37, 10
                  32, 10
                L 5, 10
                Q 0, 10
                  0, 13 
                L 0, 135
            " />
                <!-- <path d="
            M 0,21.5
            L 10,21.5
            " />
                <path d="
            M 0,35
            L -10,35
            " />
                <path d="
            M 0,49
            L 10,49
            " /> -->
            </svg>
            <svg class="mediumSVG" viewBox="-50 -2.5 100 150">
                <path d="
                M 15,0
                L 32,0
                Q 37, 0
                  37, 3
                L 37, 9
                Q 37, 12
                  32, 12
                L 5, 12
                Q 0, 12
                  0, 15 
                L 0, 135
            " />
            </svg>
            <svg class="smallSVG" viewBox="-50 -2.5 100 150">
                <!-- <path d="
                M 15,0
                L 30,0
                Q 35, 0
                  35, 3
                L 35, 7
                Q 35, 10
                  30, 10
                L 5, 10
                Q 0, 10
                  0, 13 
                L 0, 58.5
                Q 0, 61.5
                  1.5, 61.5
            " /> -->
                <!-- <path d="
                M 15,0
                L 30,0
                Q 35, 0
                  35, 3
                L 35, 7
                Q 35, 10
                  30, 10
                L 5, 10
                Q 0, 10
                  0, 13 
                L 0, 64
                Q 0, 64
                  1.5, 66
            " /> -->
                <path d="
                M 15,0
                L 33,0
                Q 38, 0
                  38, 3
                L 38, 11
                Q 38, 14
                  33, 14
                L 5, 14
                Q 0, 14
                  0, 17 
                L 0, 150
            " />
                <!-- <path d="
            M 0,21.5
            L 10,21.5
            " />
                <path d="
            M 0,35
            L -10,35
            " />
                <path d="
            M 0,49
            L 10,49
            " /> -->
            </svg>
            <p class="whyUcciHeading">
                <span class="backgroundEllipse">{{data?.section4?.heading}}</span>
            </p>
            <p class="whyUcciContent" [innerHTML]="data?.section4?.content"></p>
            <div class="announcementSection">
                <div class="row announcementRow">
                    <ng-container *ngFor="let item of data?.section4?.announcement; index as index">
                        <div class="col-md-6 col-12">
                            <ng-container *ngIf="index % 2 != 0">
                                <div class="imageSection">
                                    <img src="assets/img/ucci-images/Vector.png" alt="">
                                </div>
                                <p class="announcementHeading">
                                    {{item?.heading}}
                                </p>
                                <p class="announcementContent" [innerHTML]="item?.content"></p>
                            </ng-container>
                        </div>
                        <div class="col-md-6 col-12">
                            <ng-container *ngIf="index % 2 == 0">
                                <div class="imageSection">
                                    <img src="assets/img/ucci-images/Vector.png" alt="">
                                </div>
                                <p class="announcementHeading">
                                    {{item?.heading}}
                                </p>
                                <p class="announcementContent" [innerHTML]="item?.content"></p>
                            </ng-container>
                        </div>
                    </ng-container>
                </div>
                <div class="announcementCard">
                    <!-- <div class="hanger"></div> -->
                    <div class="cardSection">
                        <div class="cardHeading">
                            <i class="pi pi-volume-up"></i>
                            Announcement
                        </div>
                        <div class="cardContent" [innerHTML]="data?.section4?.cardContent">
                            <!-- {{data?.section4?.cardContent}} -->
                        </div>
                        <div class="buttonDiv text-center">
                            <button class="homeBUtton" (click)="navigate(data?.section4?.slug)">Discover More</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="lineSection">
                <img src="./../../../../../assets/img/ucci-images/announcement.png" alt="">
            </div> -->
        </div>
    </section>
    <div class="new_notification"></div>
    <section class="newsSection" *ngIf="news?.length > 0">
        <div class="eventSection">
            <div class="eventBannerContent">
                <p class="eventBannerHeading">
                    <span>
                        <!-- {{data?.section6?.heading}} -->
                        News & Notifications
                        <!-- <hr> -->
                    </span>
                </p>
                <!-- <p class="eventBannerSubHeading" [innerHTML]="data?.section6?.content"></p> -->
            </div>
            <div class="eventCarouselSection">
                <!-- {{data?.section6?.event | json}} -->
                <ng-container *ngIf="news?.length > 0">
                    <p-carousel [value]="news" styleClass="custom-carousel" [numVisible]="3" [numScroll]="1"
                        [circular]="true" [responsiveOptions]="responsiveOptions">
                        <ng-template let-news pTemplate="item">
                            <div class="eventDetails" *ngIf="(news | json) != '{}'">
                                <!-- <div class="eventImage">
                                    <img [src]="event.coverage_image" alt="">
                                    <div class="absoluteSection">
                                        <div class="absoluteStrip">
                                            <p class="absoluteContentSection">{{event.date | date}}</p>
                                        </div>
                                    </div>
                                </div> -->
                                <div class="eventCard">
                                    <p class="eventHeading">
                                        {{news.title | slice : 0 : 32}} <span *ngIf="news.title > 32">...</span>
                                    </p>
                                    <p class="eventContent" [innerHTML]="news?.description"></p>
                                    <!-- <div class="eventBtn">
                                        <button class="homeBUtton"
                                            (click)="navigate('event-details/'+event.id)">Explore</button>
                                    </div> -->
                                </div>
                            </div>
                            <div class="eventDetails" *ngIf="(news | json) == '{}'"></div>
                        </ng-template>
                    </p-carousel>
                </ng-container>
            </div>
            <div class="viewAllEvent text-center" style="margin: 30px 0;">
                <button class="homeButtonBlue" (click)="navigate('resources')">View All</button>
            </div>
        </div>
    </section>
    <section class="marketplaceSection">
        <div class="row m-0">
            <div class="col-md-4 col-12">
                <div class="marketPlaceHeadings">
                    <p class="marketPlaceHeading">{{data?.section5?.heading}}</p>
                    <p class="marketPlaceSubHeading">{{data?.section5?.subheading}}</p>
                    <!-- <p class="categoryHeading">{{data?.section5?.categoryHeading}}</p> -->
                    <button class="homeBUtton" (click)="navigate('marketplace-products')">View Categories</button>
                </div>
                <span class="circleDesignThree"></span>
            </div>
            <div class="col-md-8 col-12">
                <div class="row m-0">
                    <div class="col-md-4 col-12 categoryCol" *ngFor="let item of data?.section5?.categories">
                        <div class="categorySection" (click)="navigate('marketplace-products')">
                            <div class="categoryImage">
                                <img src="{{item?.icon}}" alt="" style="border-radius: 100%;">
                            </div>
                            <div class="categoryContentSection">
                                <p class="categoryContent">{{item?.heading}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- AFD section -->
    <section class="newsSection" *ngIf="news?.length > 0">
        <div class="eventSection">
            <div class="eventBannerContent">
                <p class="eventBannerHeading">
                    <span>Aao Factory Dekhein</span>
                </p>
            </div>
            <div class="eventCarouselSection">
                <ng-container *ngIf="afdPages?.length > 0">
                    <p-carousel [value]="afdPages" styleClass="custom-carousel" [numVisible]="3" [numScroll]="1"
                        [circular]="true" [responsiveOptions]="responsiveOptions">
                        <ng-template let-news pTemplate="item">
                            <div class="eventDetails">
                                <div class="eventImage">
                                    <img [src]="news?.banner" alt="" style="height: 234px;">
                                </div>
                                <div class="eventCard">
                                    <p class="eventHeading">{{ news?.name }}</p>
                                    <div class="eventBtn">
                                        <!-- <a [routerLink]="'/' + news?.slug" class="homeButton">Explore</a> -->
                                        <button class="homeBUtton" (click)="navigate('/' + news?.slug)">Explore</button>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </p-carousel>
                </ng-container>
            </div>
            <div class="viewAllEvent text-center" style="margin: 30px 0;">
                <button class="homeButtonBlue" (click)="navigate('/aao-factory-dekhein')">View All</button>
            </div>
        </div>
    </section>
    
    <!-- AFd section end -->
    
    <section class="eventSection">
        <div class="eventBannerContent">
            <p class="eventBannerHeading">
                <span>
                    {{data?.section6?.heading}}
                    <hr>
                </span>
            </p>
            <p class="eventBannerSubHeading" [innerHTML]="data?.section6?.content"></p>
        </div>
        <div class="eventCarouselSection">
            <!-- {{data?.section6?.event | json}} -->
            <ng-container *ngIf="data?.section6?.event?.length > 0">
                <p-carousel [value]="data?.section6?.event" styleClass="custom-carousel" [numVisible]="3"
                    [numScroll]="1" [circular]="true" [responsiveOptions]="responsiveOptions">
                    <ng-template let-event pTemplate="item">
                        <div class="eventDetails" *ngIf="(event | json) != '{}'">
                            <div class="eventImage">
                                <img [src]="event.coverage_image" alt="">
                                <div class="absoluteSection">
                                    <div class="absoluteStrip">
                                        <p class="absoluteContentSection">{{event.date | date}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="eventCard">
                                <p class="eventHeading">
                                    {{event.event_name | slice : 0 : 32}} <span *ngIf="event.event_name > 32">...</span>
                                </p>
                                <p class="eventContent" [innerHTML]="event?.description"></p>
                                <div class="eventBtn">
                                    <button class="homeBUtton"
                                        (click)="navigate('event-details/'+event.id)">Explore</button>
                                </div>
                            </div>
                        </div>
                        <div class="eventDetails" *ngIf="(event | json) == '{}'"></div>
                    </ng-template>
                </p-carousel>
            </ng-container>
        </div>
        <div class="viewAllEvent text-center" style="margin: 30px 0;">
            <button class="homeButtonBlue" routerLink="event-grid">View All</button>
        </div>
    </section>
    <section class="ctaSection">
        <div class="membershipApply">
            <div class="ctaHeading">
                {{data?.section7?.heading}}
            </div>
            <div class="row m-0 applyRow">
                <div class="col-md-8 col-12 apply_now_content">
                    <p class="ctaContent" [innerHTML]="data?.section7?.content"></p>
                </div>
                <div class="col-md-4 col-12 apply_now_image">
                    <img (click)="applyForMember()" src="../../../../../assets/img/ucci-images/Apply now butto.png" alt="">
                    <!-- <img (click)="applyForMember()" src="{{data?.section7?.applyNowImage}}" alt=""> -->
                </div>
            </div>
            <!-- <button class="homeButtonBlue" (click)="applyForMember()">Apply Now</button> -->
        </div>
    </section>
    <section class="contactUsSection">
        <div class="contactUs">
            <p class="contactContent">
                {{data?.section8?.heading}}
            </p>
            <div class="iFrameFormSection">
                <div class="row frameRow">
                    <div class="col-md-6 col-12">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3628.366490954362!2d73.74318641431398!3d24.576545062631876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3967efa618267461%3A0x5f995af80bfc5c1f!2sUdaipur%20Chamber%20of%20Commerce%20%26%20Industry%20(UCCI)!5e0!3m2!1sen!2sin!4v1673844267277!5m2!1sen!2sin"
                            style="border:0;" allowfullscreen="" loading="lazy"
                            referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div class="col-md-6 col-12">
                        <form (ngSubmit)="contactUs(contact)" #contact="ngForm">
                            <div class="row">
                                <div class="col-lg-6 col-12">
                                    <div class="form-group formControlDiv">
                                        <input type="text" class="formControl" name="name" #name="ngModel"
                                            [(ngModel)]="contactInfo.name" placeholder="Enter Your Name" required>
                                        <div *ngIf="contact.submitted || name.touched" class="alert alert-danger">
                                            <div *ngIf="name.errors?.['required']">
                                                Name is Required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group formControlDiv">
                                        <input type="email" class="formControl" name="email" #email="ngModel"
                                            [(ngModel)]="contactInfo.email" pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                                            placeholder="Enter E-mail Here" required>
                                        <div *ngIf="contact.submitted || email.touched" class="alert alert-danger">
                                            <div *ngIf="email.errors?.['required']">
                                                Email is Required.
                                            </div>
                                            <div *ngIf="email.errors?.['pattern']">
                                                Email is Invalid.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group formControlDiv">
                                        <input type="number" class="formControl" name="phonenumber"
                                            #phonenumber="ngModel" [(ngModel)]="contactInfo.phonenumber"
                                            pattern="^[0-9]{10}$"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                            maxlength="10" placeholder="Enter Phone Number Here" required>
                                        <div *ngIf="contact.submitted || phonenumber.touched"
                                            class="alert alert-danger">
                                            <div *ngIf="phonenumber.errors?.['required']">
                                                Phone Number is Required.
                                            </div>
                                            <div *ngIf="phonenumber.errors?.['pattern']">
                                                Phone Number is Invalid.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-12">
                                    <div class="form-group formControlDiv">
                                        <input type="text" class="formControl" name="website" #website="ngModel"
                                            [(ngModel)]="contactInfo.website"
                                            pattern="^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$"
                                            placeholder="Enter Your Website" required>
                                        <div *ngIf="contact.submitted || website.touched" class="alert alert-danger">
                                            <div *ngIf="website.errors?.['required']">
                                                Website is Required.
                                            </div>
                                            <div *ngIf="website.errors?.['pattern']">
                                                Website is Invalid.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group formControlDiv">
                                        <textarea class="formControl h-auto" name="message" id="message" cols="50"
                                            rows="4" placeholder="Enter Message Here" [(ngModel)]="contactInfo.message"
                                            #message="ngModel"></textarea>
                                        <!-- <div *ngIf="contact.submitted || message.touched" class="alert alert-danger">
                                            <div *ngIf="website.errors?.['required']">
                                                message is Required.
                                            </div>
                                        </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="submitButton">
                                <button type="submit" class="homeBUtton">Submit Now</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="contactCards">
                    <ng-container *ngFor="let item of data?.section8?.contactInfo">
                        <div class="infoCard">
                            <div class="contactCardHeadSection">
                                <img src="{{item?.icon}}">
                                <p class="contactCardHeading">{{item?.heading}}</p>
                            </div>
                            <input type="text" [value]="item?.content1" style="display : none;">
                            <input type="text" [value]="item?.content2" style="display : none;">
                            <p class="contactCardContent" pTooltip="Click to Copy" tooltipPosition="bottom"
                                [cdkCopyToClipboard]="item?.content1" *ngIf="item?.content1">{{item?.content1}}</p>
                            <p class="contactCardContent" pTooltip="Click to Copy" tooltipPosition="bottom"
                                [cdkCopyToClipboard]="item?.content2" *ngIf="item?.content2">{{item?.content2}}</p>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>
    </section>
</section>