<!-- <app-navbar-style-two></app-navbar-style-two> -->
<!-- <app-header></app-header> -->
<!-- Start Listings Details Area -->
<div style="height: 100vh;" *ngIf="viewHeight">

</div>
<section class="listings-details-area pb-70 mt-1" *ngFor="let detail of getDataFromId; index as index">
    <!-- <div class="listings-details-image">
        <img [src]="getImageUrl(getMainImage.media)" alt="image" style="height: 315px;
        width: fit-content;
        margin: auto;
        display: flex;">
    </div> -->
    <div class="listings-details-nav">
        <div class="container">
            <ul class="nav nav-tabs">
                <li class="nav-item"><a class="nav-link">{{detail?.business_name}}</a></li>
                <!-- <li class="nav-item"><a class="nav-link" (click)="scroll('overview')">Overview</a></li>
                <li class="nav-item"><a class="nav-link " (click)="scroll('gallery')">Gallery</a></li> -->
            </ul>
        </div>
    </div>
    <div class="container mt-5">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="listings-details-desc">
                    <!-- <div style="display: flex; align-items: center;">
                        <h2 style="color: #000; font-size: 1.6rem; margin-left: 12px;">{{detail.product_name}}</h2>
                    </div> -->
                    <h3>Description</h3>
                    <!-- <p>Category : {{detail?.category?.name}}</p>
                    <p>Contact : {{detail?.contact_no}}</p>
                    <p>Email : {{detail?.email}}</p> -->
                    <p style="font-size: 18px;">
                        {{detail.description}}
                    </p>
                    <h3>Gallery</h3>
                    <div id="gallery">
                        <div class="gallery-slides">
                            <owl-carousel-o [options]="galleryOptions">
                                <ng-template carouselSlide *ngFor="let Image of detail.medias;">
                                    <div class="single-image-box">
                                        <img [src]="getImageUrl(Image.media)" alt="image">
                                    </div>
                                </ng-template>
                            </owl-carousel-o>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="listings-sidebar">
                    <div>
                        <img [src]="getImage(detail?.logo,'logo')" style="height: 70px;
                                width:100px; margin-bottom: 30px;">
                        <!-- <h3>Raise Enquiry</h3> -->
                        <!-- <a (click)="displayBasic = true" class="default-btn">Enquiry Now</a> -->
                    </div>
                    <div class="listings-widget listings_contact_details">
                        <h3>Details</h3>
                        <!-- <ul *ngFor="let data of getDataFromId"> -->
                        <ul>
                            <li><i class='bx bx-purchase-tag'></i> <a>{{detail?.category?.name}}</a></li>
                            <li>
                                <i class='bx bx-globe'></i>
                                <a [href]="formatWebsiteUrl(detail?.website)">{{ detail?.website ?? '-' }}</a>
                              </li>
                            <li><i class='bx bx-phone-call'></i> <a href="tel:{{detail?.contact_no}}">{{detail?.contact_no}}</a>
                            </li>
                            <li><i class='bx bx-envelope'></i> <a href="mailto:{{detail?.email}}"
                                    target="_blank">{{detail?.email ??
                                    '-'}}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Listings Details Area -->
<app-footer></app-footer>
<!-- <app-footer-style-two></app-footer-style-two> -->
<!-- Inquiry Form Modal-->
<p-dialog header="Enquiry Form" [breakpoints]="{'850px' : '90vw'}" [(visible)]="displayBasic" (onHide)="closeDialog()"
    [style]="{width: '50vw'}" [modal]="true" [baseZIndex]="10000">
    <form [formGroup]="enquiryForm" (ngSubmit)="submitEnquiry(form)" #form="ngForm">
        <!-- basic information -->
        <div class="add-listings-box">
            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Name<span class="required">*</span>:</label>
                        <input type="text" class="form-control" formControlName="name" required style="height: 35px;"
                            placeholder="Enter Your Name" [maxLength]="30"
                            [class.is-invalid]="form.submitted && enquiryForm.get('name').invalid || enquiryForm.get('name').invalid && enquiryForm.get('name').touched">
                        <div class="invalid-feedback">
                            Please Enter Name
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Email Address<span class="required">*</span>:</label>
                        <input type="email" class="form-control"
                            [class.is-invalid]="form.submitted && enquiryForm.get('email').invalid || enquiryForm.get('email').invalid && enquiryForm.get('email').touched"
                            style="height: 35px;" formControlName="email" placeholder="Enter Email Address">
                        <div class="invalid-feedback" *ngIf="enquiryForm.get('email').errors?.required">
                            Please Enter Email Address
                        </div>
                        <div class="invalid-feedback" *ngIf="enquiryForm.get('email').errors?.pattern">
                            Please Enter Valid Email Address
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Mobile No<span class="required">*</span>:</label>
                        <input type="text" class="form-control" (keydown)="inputMobile($event)" style="height: 35px;"
                            maxlength="10" minlength="10"
                            [class.is-invalid]="form.submitted && enquiryForm.get('mobile').invalid || enquiryForm.get('mobile').invalid && enquiryForm.get('mobile').touched"
                            formControlName="mobile" placeholder="Enter Mobile No.">
                        <div class="invalid-feedback" *ngIf="enquiryForm.get('mobile').errors?.required">
                            Please Enter Mobile No.
                        </div>
                        <div class="invalid-feedback" *ngIf="enquiryForm.get('mobile').errors?.pattern">
                            Please Enter Valid Mobile No.
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label>Description<span class="required">*</span>:</label>
                        <textarea name="" id="" placeholder="Enter Description"
                            [class.is-invalid]="form.submitted && enquiryForm.get('description').invalid || enquiryForm.get('description').invalid && enquiryForm.get('description').touched"
                            formControlName="description" maxlength="250" style="height: auto; padding-top: 10px;"
                            class="form-control" cols="30" rows="2"></textarea>
                        <div class="invalid-feedback">
                            Please Enter Description
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <button type="submit" class="form-control enquiry-submit"
                        style="margin-left : auto;width: fit-content;">Submit</button>
                </div>
            </div>
        </div>

    </form>
</p-dialog>