import { ViewportScroller } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { MessageService } from 'primeng/api';
import { MarketplaceService } from 'src/app/services/marketplace.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-listings-details',
    templateUrl: './listings-details.component.html',
    styleUrls: ['./listings-details.component.scss']
})
export class ListingsDetailsComponent implements OnInit {
    getDataFromId: any = [];
    displayBasic: boolean = false;
    viewHeight: boolean = true;
    productId: any;
    public marketplace_media_url = environment.marketplace_media_url;
    baseUrl = environment.marketplace_media_logo;

    enquiryForm = new FormGroup({
        name: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.pattern('[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}')]),
        mobile: new FormControl('', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]),
        description: new FormControl('', [Validators.required])
    })
    constructor(private marketplaceservice: MarketplaceService, private route: ActivatedRoute, private Router: Router, private MessageService: MessageService, private scroller: ViewportScroller, private sanitizer: DomSanitizer) { }

    ngOnInit(): void {

        let slug = this.route.snapshot.params;
        console.log(slug);
        this.getProductById(slug);
    }

    galleryOptions: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        autoplayHoverPause: true,
        autoplay: true,
        margin: 30,
        navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ],
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 2,
            },
            768: {
                items: 2,
            },
            992: {
                items: 2,
            }
        }
    }
    singleImageBox = [
        {
            img: 'assets/img/gallery/gallery1.jpg'
        },
        {
            img: 'assets/img/gallery/gallery2.jpg'
        },
        {
            img: 'assets/img/gallery/gallery3.jpg'
        },
        {
            img: 'assets/img/gallery/gallery4.jpg'
        },
        {
            img: 'assets/img/gallery/gallery5.jpg'
        }
    ]

    customOptions: OwlOptions = {
        loop: true,
        nav: true,
        dots: false,
        animateOut: 'fadeOut',
        animateIn: 'fadeIn',
        autoplayHoverPause: true,
        autoplay: true,
        mouseDrag: false,
        items: 1,
        navText: [
            "<i class='flaticon-left-chevron'></i>",
            "<i class='flaticon-right-chevron'></i>"
        ]
    }
    // get product by id
    getMainImage: any;
    getProductById(slug: any) {
        this.productId = slug.id;
        this.marketplaceservice.getMarketplaceProductsById(slug.id).subscribe((res: any) => {
            this.getDataFromId.push(res.data);
            this.viewHeight = false;
            console.log(this.getDataFromId);
            // this.getEditFormValue();
            this.getDataFromId[0].medias.filter(res => {
                // if (res.sequence == 1 || res.sequence == null) {
                // console.log(res);
                this.getMainImage = res;
                // }
            });
        })
    }

    getImageUrl(url: any) : SafeUrl {
        console.log(url);
        return this.sanitizer.bypassSecurityTrustUrl(this.marketplace_media_url + '/media/' + url);
    }

    submitEnquiry(form: NgForm) {
        if (this.enquiryForm.valid) {
            let formData = new FormData();
            let Data = this.enquiryForm.value
            for (const [key, value] of Object.entries(Data)) {
                formData.append(`${key}`, `${value}`);
            }
            formData.append('product_id', this.productId)
            this.marketplaceservice.enquiryProduct(formData).subscribe((res: any) => {
                this.MessageService.add({
                    severity: 'success',
                    // summary: 'Success',
                    detail: res.message,
                });
            })

            this.displayBasic = false;
            this.enquiryForm.reset();
            Object.keys(this.enquiryForm.controls).forEach((key: any) => {
                this.enquiryForm.controls[key].setErrors(null)
            })
            form.resetForm();
        }
    }
    // reason of add this because there is a bug in the website opening in that localhost add befor ever url so that help me to prevent that
    formatWebsiteUrl(url: string): string {
        if (!url) {
          return '#';
        }
        if (!/^https?:\/\//i.test(url)) {
          return `https://${url}`;
        }
        return url;
      }
      
    //validate user mobile number
    inputMobile(event: any) {
        if (event.keyCode != 9) {
            if (event.keyCode != 8) {
                if (!/^[0-9]$/.test(event.key)) {
                    event.preventDefault();
                }
            }
        }
    }


    closeDialog() {
        // this.enquiryForm.reset();
        // Object.keys(this.enquiryForm.controls).forEach((key:any)=>{
        //     this.enquiryForm.controls[key].setErrors(null)
        // })
    }


    scroll(el: any) {
        this.scroller.scrollToAnchor(el);
        // el.scrollIntoView();
    }
    // image path url
    getImage(path?: string, end_point?: any) {
        let returnValue: any;
        returnValue = this.baseUrl;
        returnValue = returnValue + end_point + '/' + path;
        return returnValue;
    }

}